export function api_url() {
    var api_url = '';
    var protocol = document.location.protocol;
    var host = window.location.hostname;
    if (host === "localhost" || host === 'dbd0d5452885.ngrok.io') {
        api_url = 'http://localhost:1337';
    } else if (host === "testpayments.pabbly.com" || host === "testp.pabbly.com") {
        api_url = 'https://testpayments.pabbly.com/api';
    } else if (host === "mg-mail4.com") {
        api_url = 'http://mg-mail4.com/api';
    } else if (host === "payments.pabbly.com") {
        api_url = 'https://payments.pabbly.com/api';
    } else {
        // api_url = 'https://payments.pabbly.com/api';        
        api_url = protocol + '//' + host + '/api';
    }
    return api_url;
}