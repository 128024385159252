import React from 'react';
import '../../css/loader.css';
class Loader extends React.Component {
    render() {
        var styleclass = 'sk-three-bounce ' + this.props.className;
        const { style } = this.props;
        return (
            <div className={styleclass} style={style}>
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
            </div>
        );
    }
}

export default Loader;