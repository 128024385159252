export function accountpath(path = '') {
    var api_url = '';
    var host = window.location.hostname;
    if (path === 'logout') {
        if (host === "localhost") {
            api_url = 'http://localhost:5000/app/' + path;
        } else if (host === "testpayments.pabbly.com") {
            api_url = 'https://testpayments.pabbly.com/app/' + path;
        } else if (host === "mg-mail4.com") {
            api_url = 'http://accounts.mg-mail4.com/' + path + '?s=pabbly-subscriptions&host=' + host;
        } else {
            api_url = 'https://accounts.pabbly.com/' + path;
        }
    } else if (path === 'login') {
        if (host === "localhost") {
            api_url = 'http://localhost:5000/signin';
        } else if (host === "testpayments.pabbly.com") {
            api_url = 'https://testpayments.pabbly.com/signin';
        } else if (host === "mg-mail4.com") {
            api_url = 'http://accounts.mg-mail4.com/' + path + '?s=pabbly-subscriptions&host=' + host;
        } else {
            api_url = 'https://accounts.pabbly.com/' + path + '?s=pabbly-subscriptions&pl=https://payments.pabbly.com/';
        }
    } else {
        api_url = document.location.origin;

    }
    return api_url;
}