
import 'react-app-polyfill/ie11';
import 'core-js';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/symbol';
import 'core-js/es/set';
import 'core-js/es/array/find';
import 'core-js/es/array/includes';
import 'core-js/es/number/is-nan';
import 'raf/polyfill';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'string.prototype.startswith';
import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import Loadable from 'react-loadable';
import Loader from './components/custom/Loader';
import domain from './components/custom/Domain';
import * as qs from 'query-string';
import callApi from './functions/callApi';
import { accountpath } from './lib/accountpath';

function Renderloader() {
    return (
        <div className="container-wrap p-t-30 p-b-30 iiii" style={{
            'height': '1167px',
        }}>
            <div className="container">
                <div className="loader-container">
                    <div className="text-center m-t-30" style={{
                        "position": "absolute",
                        "top": "250px",
                        "transform": "translateX(-50%)",
                        "left": "50%"
                    }}>
                        <Loader />
                    </div>
                </div>
            </div>
        </div>
    );
}

const Entry = Loadable({
    loader: () => import('./Entry'),
    loading() {
        return <div></div>
    }
});

const PortalEntry = Loadable({
    loader: () => import('./PortalEntry'),
    loading() {
        return <Renderloader />
    }
});

var url = window.location.pathname;
if ((url.indexOf('/portal') > -1 || url.indexOf('/affiliate/signup') > -1) && (url.indexOf('/email-notification/edit/portal_password') === -1 && url.indexOf('/email-notification/edit/portal_verification') === -1)) {
    if (url.indexOf('/portal/logout') > -1) {
        var page_url = window.location.href;
        var pageurltrack = page_url.replace(/\/$/, "");
        var user_id = pageurltrack.substr(pageurltrack.lastIndexOf('/') + 1);
        logoutPortalUser(user_id);
    } else {
        verifyPortalUser();
    }
} else {
    if (url.indexOf('/logout') > 0) {
        logoutUser();
    } else {
        verifyUser();
    }
}

/**
 * Logout the user
 */
function logoutUser() {
    callApi('user/logout', 'POST', {}, (response) => {
        if (response.status === 'success') {
            window.location.assign('/signin');
        }
    });
}

/**
 * Logout Portal the user
 */
function logoutPortalUser(user_id) {
    callApi('portal/logout', 'POST', {}, (response) => {
        if (response.status === 'success') {
            window.location.assign('/portal/signin/' + user_id);
        }
    });
}

/**
 * Verify the application access by logged in session
 */
async function verifyUser() {
    var url = window.location.pathname;
    var host = window.location.host;
    if (url.indexOf('/thankyou') < 0 &&
        url.indexOf('/subscribe') < 0 &&
        url.indexOf('/checkout') < 0 &&
        url.indexOf('/affurl') < 0 &&
        url.indexOf('/secureinvoice') < 0 &&
        url.indexOf('/forgotpassword') < 0 &&
        url.indexOf('/add-card') < 0) {
        //If host is pabbly.com or localhost, redirect to sign page
        //Else redirect to 404 page
        if (!domain.includes(host)) {
            if (url.indexOf('/404') < 0) {
                return window.location.assign('/404');
            }
        } else {
            await callApi('api/verify', 'GET', {}, (response) => {
                if (response.status === 'success') {
                    if (url === '/') {
                        window.location.assign('/app/');
                    }
                    else if (url.indexOf('signin') > 0 || url.indexOf('signup') > 0) {
                        window.location.assign('/app/');
                    }
                } else if (response.status === 'blocked') {
                    if (url.indexOf('/blocked') < 0) {
                        window.location.assign('/blocked/');
                    }
                } else {
                    //If not logged in and not on sign and signup page
                    if (url.indexOf('/signin') < 0 &&
                        url.indexOf('/signup') < 0) {
                        window.location.assign(accountpath('login'));
                    }
                }
            });
        }
    }
    ReactDOM.render(<Entry />, document.getElementById('main-wrapper'));
    registerServiceWorker();
}

/**
 * Verify the application access by Portal user logged in session
 */
async function verifyPortalUser() {
    var url = window.location.pathname;

    var pathname_split = window.location.pathname.split("/");
    var portal_name_index = '';
    pathname_split.forEach((element, index) => {
        if (element === 'portal') {
            portal_name_index = index;
        }
    });
    var portal_name = '';
    if (url.indexOf('/signin') > -1 || url.indexOf('/portal/access') > -1) {
        portal_name = pathname_split[portal_name_index + 2]
    } else {
        portal_name = pathname_split[portal_name_index + 3]
    }
    var hosturl = window.location.href;
    var page_url = '';
    pageurltrack = '';
    //If host is pabbly.com or localhost, redirect to sign page
    //Else redirect to 404 page

    // if (host.indexOf("pabbly.com") < 0 && host.indexOf("localhost") < 0) {
    //     if (url.indexOf('/404') < 0) {
    //         alert("404 called");
    //         return window.location.assign('/404');
    //     }
    // } else 
    if (hosturl.indexOf("/portal/create") > 0 || hosturl.indexOf("/portal/forgotpassword") > 0 || hosturl.indexOf("/portal/reset") > 0 || hosturl.indexOf("/affiliate/signup") > 0) {
        //  Does't need to varify the login customer
        //  because we will open a page which doen't need a session 
    } else if (hosturl.indexOf("/portal/access") > 0) {
        // below code will work while working with the tokens to login
        // token will automatically create a sesstion and we will redirect it from here to dashboard page
        var parsed = qs.parse(window.location.search);
        page_url = window.location.pathname;
        pageurltrack = page_url.replace(/\/$/, "");
        var token = parsed.tk;

        var values = {};
        values.token = token;
        values.portal_name = portal_name;

        await callApi('portal/access', 'POST', values, (response) => {
            if (response.status === 'success') {
                if (typeof response.data.redirect_url !== 'undefined' && response.data.redirect_url !== '') {
                    window.location.assign(response.data.redirect_url);
                } else {
                    if (url.indexOf('/portal/app')) {
                        window.location.assign('/portal/app/en/' + portal_name);
                    }
                }
            } else {
                //If not logged in and not on sign and signup page
                if (url.indexOf('/signin') < 0 &&
                    url.indexOf('/signup') < 0) {
                    window.location.assign('/portal/signin/' + portal_name);
                }
            }
        });
    } else {
        // below code will run for normal login process
        page_url = window.location.href;
        pageurltrack = page_url.replace(/\/$/, "");
        await callApi('portal/verify/' + portal_name, 'GET', {}, async (response) => {
            if (response.status === 'success') {
                if (url.indexOf('/portal/app')) {
                    /**
                     * Code to get default language selected by merchant for client portal
                     */
                    callApi('user/getprofilebyportal/' + portal_name, 'GET', {}, async (response) => {
                        var redirect = '';
                        var parsed = qs.parse(window.location.search);
                        var affiliate_signup = parsed.affiliate_signup;

                        if (response.status === 'success') {
                            var fetch_portal_data = response.data;
                            var default_lang = 'en';
                            if (fetch_portal_data.portal_data !== 'undefined') {
                                default_lang = typeof fetch_portal_data.portal_data.default_portal_language !== 'undefined' ? fetch_portal_data.portal_data.default_portal_language : 'en'
                            }

                            redirect = '/portal/app/' + default_lang + '/' + portal_name;
                            if (affiliate_signup) {
                                await callApi('portal/fetchportalsetting', 'GET', {}, (response) => {
                                    if (response && response.status === 'success' && response.data && response.data.client_detail) {

                                        if (response.data.client_detail.is_affiliate) {
                                            redirect = `/portal/app/${default_lang}/${portal_name}/affiliate/settings/banner-links`;
                                        } else {
                                            redirect = `/portal/app/${default_lang}/${portal_name}/affiliate/affiliate-request`;
                                        }
                                    }

                                    window.location.assign(redirect);
                                });
                            } else {
                                window.location.assign(redirect);
                            }


                        } else {
                            window.location.assign('/portal/app/en/' + portal_name);
                        }
                    });

                }
            } else {
                //If not logged in and not on sign and signup page
                if (url.indexOf('/signin') < 0 &&
                    url.indexOf('/signup') < 0) {
                    window.location.assign('/portal/signin/' + portal_name);
                }
            }
        });

    }

    ReactDOM.render(<PortalEntry />, document.getElementById('main-wrapper'));
    registerServiceWorker();
}